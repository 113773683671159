body {
    background: var(--background-grey);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: var(--font-dark);
}

h1, h2, h3, h4, h5 {
    font-weight: 900;
}

// Header
.header {
    position: relative;
    margin: 0 0 40px 0;
    text-align: center;
    
    .button {
        position: absolute;
        top: 4px;
        right: 0;
        width: 180px;
        padding: 15px;
        border-radius: 6px;
        text-align: center;
        font-weight: 900;
        font-size: 12px;
        cursor: pointer;
        text-transform: uppercase;
        background: #fff;
        transition: all 0.2s ease;
        border: 1.8px solid #fff;

        &:hover {
            background: rgba(255,255,255,0.9);
        }

        &.second {
            right: 200px;
            color: #fff;
            border: 1.8px solid #fff;
            background: none;
        }

        &.third {
            right: 400px;
            color: #fff;
            border: 1.8px solid #fff;
            background: none;
        }
    }

    h1 {
        color: #fff;
        text-transform: uppercase;
        font-size: 24px;
    }

    p {
        color: var(--font-light);
        margin: 0;
        padding: 0;
    }
}

// No Results
.no-results {
    border-radius: 6px;
    background: #fff;
    padding: 20px;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;

    img { width: 100%; }

    h5 {
        font-size: 12px;
        margin: 0;
    }

    h4 {
        margin: 4px 0 0 0;
        padding: 0;
        font-style: italic;
        font-weight: 900;
        font-size: 20px;
    }

    p {
        color: var(--font-light);
        font-size: 15px;
    }
}

// Footer

// Overrides
.ion-page {
    display: block;
}

.authenticated {
    z-index: 5;
}

.page {
    padding: 0;
    overflow-y: scroll;
    height: 100vh;

    .content {
        position: relative;
        padding: 0px 40px 110px 40px;
        z-index: 15;
    }
}

ion-modal {
    .modal-header {
        position: relative;
        padding: 20px 20px;
        background: var(--background-grey);

        h5 {
            margin: 0;
            padding: 0;
            color: var(--font-dark);
            text-align: center;
            font-weight: 900;
            font-style: italic;
            font-size: 16px;
            text-transform: uppercase;
        }

        ion-icon {
            position: absolute;
            top: 17px;
            right: 15px;
            font-size: 24px;
            cursor: pointer;
            color: #7b7b83;
            opacity: 0.3;
            transition: all 0.2s ease;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

ion-modal::part(content) {
    border-radius: 8px;
    box-shadow: 0px 10px 44px -35px rgba(27,32,38,1);
}