@media only screen and (max-width: 960px) {
    #header {
        height: 300px !important;
        margin: 0px 0 -210px 0 !important;

        .user { right: 28px !important; }
        .logo { width: 150px !important; }
        .menu { left: 28px !important; top: 32px !important; }
        .profile { right: 22px; z-index: 9999 !important; }
    }

    #menu {
        .logo { width: 150px !important; }
        .menu {
            margin: -180px 0 0 -250px !important;

            li { font-size: 32px !important; }
        }
    }
    
    .header {
        z-index: 1 !important;
        margin: 0 0 60px 0 !important;
        h1 { font-size: 20px; margin: 0 0 5px 0; }
        p { font-size: 15px; }
        .button { position: relative !important; margin: 30px auto 0 auto; }
        .second { position: relative !important; margin: 30px auto 0 auto; }
        .third { position: relative !important; margin: 30px auto 0 auto; }
    }

    .login {
        width: 100% !important; 
        margin: -215px 0 0 0 !important; 
        left: auto !important; 
        padding: 0 40px; 

       .text h2 { 
           font-size: 22px !important; 
       }
   }

    // Grid Changes
    .grid-list li div { 
        float: none !important; 
        width: 100% !important; 
        margin: 0 0 10px 0; 
        text-align: left !important; 

        &.menu {
            position: absolute;
            width: auto !important;
            top: 20px !important;
            right: 20px !important;
    
            ion-icon { font-size: 20px !important; }
        }
    }

    .statistics {
        .list {
            li { 
                width: 50%;

                h1 { font-size: 32px; }
                h5 { font-size: 11px;}
            }
            li:nth-child(2n) { border: none; }
            li:nth-child(3) { padding: 10px 0; }
            li:nth-child(4) { padding: 10px 0; }
        }
    }
    
    .app-modal-item::part(content) {
        height: 100% !important;
    }

    // Dashboard
    .top {
        .graph {
            height: auto !important;
            float: none !important;
            width: 100% !important;
        }
        .stat-grid {
            height: auto !important;
            float: none !important;
            width: 100% !important;
            margin: 30px 0 -10px 0 !important;
            border: none !important;

            .list {
                border: none !important;

                li {
                    float: none;
                    width: 100% !important;
                    border: none !important;
                    padding: 20px !important;
                    border-top: 1px solid #dadcdf !important;

                    &:last-child {
                        border: none !important;
                        border-top: 1px solid #dadcdf !important;
                    }
                }
            }
        }
    }

    // Footer Changes
    .footer {
        .copy {
            width: 100%; 
            text-align: center;
            margin: 0 auto;
            float: none !important; 
        }
        .social { 
            margin: 10px auto 0 auto; 
            float: none !important; 
            width: 200px; 
        }
    }

    #footer {
        .copy {
            width: 100%; 
            text-align: center; 
            margin: 0 auto; 
            float: none !important;
        }
        .social { 
            margin: 10px auto 0 auto; 
            float: none !important; 
            width: 200px; 
        }
    }
}