.grid-list {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 5;

    li {
        position: relative;
        display: block;
        margin: 0;
        padding: 20px;
        font-size: 14px;
        cursor: pointer;
        background: var(--background-grey-dark);
        overflow: hidden;
        border-radius: 6px;
        transition: all 0.2s ease;

        &:nth-child(even) {
            background: var(--background-grey);
        }

        &:hover {
            background: #fff;
        }

        .id {
            float: left;
            width: 10%;
        }
        
        .type {
            float: left;
            width: 9%;
            
            .label {
                font-size: 13px;
                margin: 5px 0 0 0;
                color: var(--font-light);
                
                &.complete { color: #0aa30a; }
                &.processed { color: #2f5aaa; }

                ion-icon {
                    float: left;
                    margin: 0px 4px 0 0;
                    font-size: 16px;
                }
            }
        }

        .name {
            float: left;
            width: 20%;
        }

        .body {
            float: left;
            width: 50%;
        }

        .email {
            float: left;
            width: 25%;
        }

        .address {
            float: left;
            width: 20%;
        }

        .sid {
            float: left;
            width: 32%;
            padding: 0 20px 0 0;
        }

        .reference {
            float: left;
            width: 15%;
        }

        .updated {
            float: left;
            width: 15%;
        }

        .money {
            float: left;
            width: 7%;
        }

        .lot {
            float: left;
            width: 6%;
        }
        
        .menu {
            position: absolute;
            top: 20px;
            right: 20px;
            text-align: right;

            ion-icon {
                font-size: 23px;
                color: var(--font-dark);
                padding: 0 0 0 10px;

                &.view {
                    padding: 0;
                }

                &.delete {
                    color: red;
                }
            }
        }

        h5 {
            margin: 0 0 5px 0;
            padding: 0;
            color: var(--font-light);
            font-size: 11px;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        p {
            margin: 0;
            padding: 0;
            color: var(--font-dark);
            font-weight: 500;
            font-size: 15px;
        }
    }
}

// Forms
.form {
    position: relative;
    padding: 0;
    margin: 0;

    .group {
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;

        &.two {
            .input { 
                float: left;
                width: 50%; 
                padding: 3px 10px;
            }
        }
        
        &.three {
            .input { 
                float: left;
                width: 33.3333%; 
                padding: 3px 10px;
            }
        }

        .input { 
            width: 100%; 
            padding: 3px 10px;

            h5 {
                font-size: 14px;
                color: var(--font-dark);
            }

            input {
                width: 100%;
                padding: 12px;
                -webkit-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                -moz-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                border: 1.8px solid #e1e4e8;
                background: #fff;
                border-radius: 6px;
                transition: all 0.2s ease;
                color: var(--font-dark);

                &:focus {
                    border: 1.5px solid #1c1e20;
                    outline: none;
                }
            }

            textarea {
                width: 100%;
                padding: 12px;
                height: 120px;
                -webkit-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                -moz-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                border: 1.5px solid #e1e4e8;
                background: #fff;
                border-radius: 6px;
                resize: none;
                color: var(--font-dark);

                &:focus {
                    border: 1.5px solid #1c1e20;
                    outline: none;
                }
            }

            select {
                width: 100%;
                margin: 0;
                padding: 12px;
                border: 1.5px solid #e1e4e8;
                background: #fff;
                border-radius: 6px;
                -webkit-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.35);
                -moz-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.35);
                box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.35);
                color: var(--font-dark);

                &:focus {
                    border: 1.5px solid #1c1e20;
                    outline: none;
                }

                &.error {
                    border: 1.5px solid red;
                }
            }
        }

        &.checkbox {
            position: relative;
            padding: 12px 15px;
            margin: 20px 10px 0 10px;
            background: #f2f2f3;
            border: 1.5px solid #cbcccf;
            border-radius: 5px;

            input { margin: 0 5px 0 0; }
        }
    }

    .button {
        padding: 0 10px;

        button {
            width: 100%;
            padding: 18px 16px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 900;
            margin: 20px 0px 0 0px;
            color: #fff;
            text-transform: uppercase;
            background: #1c1e20;
            transition: all 0.2s ease;
            -webkit-box-shadow: 0px 0px 33px -9px rgba(27, 29, 36, 0.25);
            -moz-box-shadow: 0px 0px 33px -9px rgba(52, 54, 61, 0.25);
            box-shadow: 0px 0px 33px -9px rgba(27, 29, 36, 0.25);
            
            &:hover {
                background: rgb(28, 30, 32, 0.9);
            }

            &.disabled {
                background-color: #c3c5c8;
                height: 50px;
                padding: 10px 0;
                
                img {
                    width: 30px;
                    margin: 0 auto;
                    mix-blend-mode: multiply;
                }
            }
        }
    }

}

// Stats
.statistics {
    position: relative;
    border-radius: 6px;
    background: #fff;
    padding: 20px;
    margin: 0 auto 40px auto;
    text-align: center;
    box-shadow: 0px 10px 56px -34px rgba(16,18,36,0.6);
    
    &.metrics {
        .list {
            padding: 20px 0;
        }
    }

    .list {
        position: relative;
        margin: 0;
        padding: 20px 0 0 0;
        overflow: hidden;
        
        &.single {
            padding: 10px 0;
        }

        &.filters {
            border-bottom: 1px solid #dadcdf;
            padding: 0 0 20px 0;

            li {
                padding: 0;
            }
        }

        li {
            position: relative;
            display: block;
            float: left;
            width: 25%;
            margin: 0;
            padding: 0 15px;
            border-right: 1px solid #dadcdf;
            
            &:last-child {
                border: none;
            }

            h1 {
                font-size: 34px;
                margin: 0;
                padding: 0;
                float: right;
            }

            h5 {
                float: left;
                font-size: 12px;
                margin: 7px 0 5px 0;
                color: var(--font-light);
                letter-spacing: 1px;
                text-align: left;
            }
            
            .notice {
                position: absolute;
                top: 14px;
                right: 100px;
                font-size: 12px;
                text-transform: uppercase;

                &.active {
                    color: rgb(162, 41, 41);
                }
            }

            .input { 
                width: 100%; 
                padding: 3px 20px 3px 20px;

                h5 {
                    float: none;
                    margin: 0px 0 10px 0;
                    text-align: center;
                }

                input {
                    width: 100%;
                    padding: 10px;
                    -webkit-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                    -moz-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                    box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.25);
                    border: 1.8px solid #e1e4e8;
                    background: #fff;
                    border-radius: 6px;
                    font-size: 14px;
                    transition: all 0.2s ease;
                    color: var(--font-dark);
    
                    &:focus {
                        border: 1.5px solid #1c1e20;
                        outline: none;
                    }
                }

                select {
                    width: 100%;
                    margin: 0;
                    padding: 10px;
                    border: 1.5px solid #e1e4e8;
                    background: #fff;
                    border-radius: 6px;
                    -webkit-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.35);
                    -moz-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.35);
                    box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.35);
                    color: var(--font-dark);
                    font-size: 14px;
                    &:focus {
                        border: 1.5px solid #1c1e20;
                        outline: none;
                    }
                }
            }
        }
    }
}

// Pagination
.pagination {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 15px 0;
    z-index: 5;
    border-top: 1px solid #cdcdd3;
    background-color: var(--background-grey);

    .ngx-pagination {
        text-align: center;
        padding: 0px 0;
        margin: 0;

        li {
            display: inline-block;
            background: #fff;
            margin: 0 5px;
            padding: 5px 0;
            font-size: 13px;
            font-weight: 500;
            min-width: 37px;
            height: 35px;
            border: 1px solid #dce0e2;
            border-radius: 5px !important;
            overflow: hidden;
            -webkit-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.29);
            -moz-box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.29);
            box-shadow: 0px 0px 22px -9px rgba(27, 29, 36, 0.29);

            &:hover {
                background: rgba(255, 255, 255, 0.3);
            }
            
            a:hover {
                background: none;
            }

            &.pagination-previous {
                width: 92px;
                height: 35px;
                padding: 7px !important;
            }
            
            &.pagination-next {
                width: 92px;
                height: 35px;
                padding: 7px !important;

                span {
                    display: none;
                }
            }

            &.current {
                margin: 0 5px;
                width: 37px;
                height: 35px;
                padding: 9px !important;
                color: var(--font-dark);
                border: 1.5px solid #1f68de;
                background: rgba(31, 104, 222, 0.1);
            }

            &.small-screen {
                display: none;
            }
        }
    }
}

// Modal Sizes
.app-modal-form::part(content) {
    height: auto;
    min-height: 280px;
    max-height: 1030px;
}

.app-modal-item::part(content) {
    height: 820px;
}

.app-modal-layout::part(content) {
    height: auto;
    min-height: 340px;
}
